import React from 'react'

type ContextType = {
  isLearnPopoverShown: boolean
  setIsLearnPopoverShown: (value: boolean) => void
}

const Context = React.createContext({} as ContextType)

export const useLayout = (): ContextType => React.useContext(Context)

type LayoutProviderProps = {
  children: React.ReactNode
}

export const LayoutProvider = ({ children }: LayoutProviderProps) => {
  const [isLearnPopoverShown, setIsLearnPopoverShown] = React.useState(false)

  const memoizedValue = React.useMemo(
    () => ({
      isLearnPopoverShown,
      setIsLearnPopoverShown,
    }),
    [isLearnPopoverShown],
  )

  return <Context.Provider value={memoizedValue}>{children}</Context.Provider>
}
