import React from 'react'
import { add, init, setUserId, track } from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import { AmplitudeEventProperties } from 'types/common'
import { ClientEnv } from 'utils/clientEnv'

const AMPLITUDE_API_KEY = ClientEnv.NEXT_PUBLIC_AMPLITUDE_API_KEY

type AmplitudeProviderProps = {
  children: React.ReactNode
}

type ContextType = {
  trackAmplitudeEvent: (eventName: string, eventProperties: AmplitudeEventProperties) => void
  setAmplitudeUserId: (userId: string) => void
}

export const AmplitudeContext = React.createContext({} as ContextType)

export const AmplitudeProvider = ({ children }: AmplitudeProviderProps) => {
  React.useEffect(() => {
    if (AMPLITUDE_API_KEY) {
      init(AMPLITUDE_API_KEY, {
        defaultTracking: {
          sessions: true,
        },
      })

      // https://www.docs.developers.amplitude.com/session-replay/sdks/plugin/#sampling-rate
      const sessionReplayTracking = sessionReplayPlugin({
        sampleRate: process.env.NODE_ENV === 'production' ? 0.2 : 0,
      })
      add(sessionReplayTracking)
    }
  }, [])

  const momoizedValue = React.useMemo(() => {
    const trackAmplitudeEvent = (eventName: string, eventProperties: AmplitudeEventProperties) => {
      track(eventName, eventProperties)
    }
    const setAmplitudeUserId = (id: string) => {
      setUserId(id)
    }

    return { trackAmplitudeEvent, setAmplitudeUserId }
  }, [])

  return <AmplitudeContext.Provider value={momoizedValue}>{children}</AmplitudeContext.Provider>
}
