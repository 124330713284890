import { z } from 'zod'

const EnvSchema = z.object({
  NEXT_PUBLIC_SENTRY_DSN: z.string().optional(),
  NEXT_PUBLIC_AMPLITUDE_API_KEY: z.string().optional(),
  NEXT_PUBLIC_HCAPTCHA_SITE_KEY: z.string(),
  NEXT_PUBLIC_TERMS_OF_SERVICE_URL: z.string(),
  NEXT_PUBLIC_PRIVACY_POLICY_URL: z.string(),
  NEXT_PUBLIC_COOKIE_POLICY_URL: z.string(),
  NEXT_PUBLIC_DISCLAIMER_URL: z.string(),
  NEXT_PUBLIC_ACCEPTABLE_USE_POLICY_URL: z.string(),
  NEXT_PUBLIC_PERSONAL_DATA_USE_FORM_URL: z.string(),
})

const NextPublicEnvVars = {
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_AMPLITUDE_API_KEY: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
  NEXT_PUBLIC_HCAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_HCAPTCHA_SITE_KEY,
  NEXT_PUBLIC_TERMS_OF_SERVICE_URL: process.env.NEXT_PUBLIC_TERMS_OF_SERVICE_URL,
  NEXT_PUBLIC_PRIVACY_POLICY_URL: process.env.NEXT_PUBLIC_PRIVACY_POLICY_URL,
  NEXT_PUBLIC_COOKIE_POLICY_URL: process.env.NEXT_PUBLIC_COOKIE_POLICY_URL,
  NEXT_PUBLIC_DISCLAIMER_URL: process.env.NEXT_PUBLIC_DISCLAIMER_URL,
  NEXT_PUBLIC_ACCEPTABLE_USE_POLICY_URL: process.env.NEXT_PUBLIC_ACCEPTABLE_USE_POLICY_URL,
  NEXT_PUBLIC_PERSONAL_DATA_USE_FORM_URL: process.env.NEXT_PUBLIC_PERSONAL_DATA_USE_FORM_URL,
}

const parsingResult = EnvSchema.safeParse(process.env)

let envRes = NextPublicEnvVars as unknown as z.infer<typeof EnvSchema>

if (parsingResult.success) {
  envRes = parsingResult.data
} else if (process.env.WITH_ENV_VALIDATION === 'true') {
  throw new Error(`Invalid WEB env variables: ${JSON.stringify(parsingResult.error.format())}`)
}

export const ClientEnv = envRes
